import React from 'react'

interface RoundButtonProps{
    text?: string;
    id?: string;
    onClick?: (e:any)=>void
}

function RoundButton({text, id, onClick}:RoundButtonProps) {
    return (
        <div className='text-center'>
            <button
                id={id}
                onClick={onClick}
                className="bg-brand select-none rounded-full font-sans text-lg	font-bold text-white px-12  py-3  sm:px-32 sm:py-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2">{text}</button>
        </div>
    )
}

export default RoundButton