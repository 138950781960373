import { HeadFC } from 'gatsby';
import React from 'react'
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Page8 from '../../components/InteractivePart/Page8/index';
import Feedback from '../../components/InteractivePart/Page2/Feedback/index';
import HomeEquity from '../../components/InteractivePart/Page7/HomeEquity/index'
import SectionTwo from '../../components/InteractivePart/Page8/Sections/SectionTwo';
import SectionThree from '../../components/InteractivePart/Page8/Sections/SectionThree';
import SectionFour from '../../components/InteractivePart/Page8/Sections/SectionFour';

const sizeClass = 'mx-auto';

function page7() {
    return (
        <main>
            <Header className={sizeClass} />
            <div className={`container py-6 lg:py-16 ${sizeClass}`}>
                <section className="flex flex-col xl:flex-row-reverse gap-8 lg:gap-16">
                    <div className="flex-1">
                        <Page8 />

                    </div>
                </section>
            </div>
            <div className='bg-white'>
                <section className={`container ${sizeClass}`}>
                    <SectionTwo />
                </section>
            </div>
            <div className='bg-background'>
                <section className={`container ${sizeClass}`}>
                <SectionThree />
                </section>
            </div>
            <div className="chart bg-white">
                <section className={`container ${sizeClass}`}>
                    <SectionFour />
                    <Feedback />
                </section>
            </div>

            <Footer className={sizeClass} />
        </main>
    )
}

export default page7;
export const Head: HeadFC = () => <title>Nuborrow.com | Home Equity Made Easy</title>;
