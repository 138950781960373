import React from 'react'
import MainHeading from './MainHeading';
import GrayText from './GrayText';
import RoundButton from './RoundButton';
import { navigate } from 'gatsby';

function SectionThree() {
    return (
        <div className='bg-background mx-auto py-7'>
            <MainHeading text={`No Realtor \n No Problem`} />
            <GrayText text={`Our program works with any Realtor, but if you don't have one we can  suggest one of our approved Realtor partners.`} />
         <RoundButton onClick={()=>navigate("/no-down-payment/pre-approved")} text='Get Pre-Approved'
         id='page8_btn_pre-approved2'/>
        </div>
    )
}

export default SectionThree;