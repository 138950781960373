import React from 'react'
import MainHeading from './MainHeading';
import GrayText from './GrayText';
import RoundButton from './RoundButton';
import { navigate } from 'gatsby';

function SectionFour() {
    return (
        <div className='bg-white py-7'>
            <MainHeading text={`We've Found Our \n Next Home`} />
            <GrayText text="Have you found the perfect home, but don't have the required down payment? Just answer a few questions and our team will get you started so you can get packing." />
            <RoundButton id='page8_btn_approved' onClick={()=>navigate("/no-down-payment/found-house")} text='Get Approved' 
            />
        </div>
    )
}

export default SectionFour;