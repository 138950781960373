import React from 'react'
import { motion } from 'framer-motion';
import { textanimation } from '../../Animation/index'


interface GrayTextProps{
    text : string;
}
function GrayText({text}:GrayTextProps) {
    return (
        <div>
            <motion.div
             variants={textanimation}
             initial="offscreen"
             whileInView="onscreen"
             viewport={{ once: true }}
            className='font-raleway text-sm md:text-2xl text-center text-[#969696] font-medium py-7 lg:w-3/5 mx-auto'>
              {text.split('\n').map((line, index) => (
                <div key={index}>
                    {line}
                </div>
            ))}
            </motion.div>
        </div>
    )
}

export default GrayText;