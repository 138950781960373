import EightPageCompoOne from './EightPageCompoOne';


function index() {

  return (
    <div id='view'>
      <EightPageCompoOne />
    </div>
  );
}

export default index;
