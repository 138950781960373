import React from 'react'
import { motion } from 'framer-motion';
import { textanimation } from '../../Animation/index'


interface MainHeadingProps {
    text: string;
}
function MainHeading({ text }: MainHeadingProps) {
    return (
        <motion.div
        variants={textanimation}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className='font-raleway text-3xl md:text-5xl text-center font-extrabold py-7'>
            {text.split('\n').map((line, index) => (
                <div key={index}>
                    {line}
                </div>
            ))}
        </motion.div>
    )
}

export default MainHeading;