import React, { useState } from 'react';
import home from '../../../assets/home.png';
import { motion } from 'framer-motion';
import { textanimation } from '../Animation/index'
import { navigate } from 'gatsby';


function EightPageCompoOne() {

  return (
    <>
      <div className='bg-background pb-20'>
        <div className="pt-20">
          <motion.div
            variants={textanimation}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            {/* sm:mx-0 text-3xl  md:text-4xl lg:text-5xl xl:text-7xl */}
            <p className="font-raleway mx-0 md:px-16 sm:mx-0 text-3xl  md:text-4xl lg:text-5xl xl:text-7xl text-center font-black">
              Buy Your Home With
              <span className="text-brand"> No Down Payment </span>
            </p>
          </motion.div>
          <motion.div
            variants={textanimation}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <p className="font-sans text-[#737373] font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-[32px] text-center mt-10 mx-12">
              Realize the dream of home ownership with our <span className='text-brand'> No Down Payment</span> purchase plan.
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col justify-center items-center mt-10 ">
          <div className="flex gap-10 sm:gap-0 flex-row w-full lg:w-[80%] flex-wrap justify-center items-center ">
            <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between  w-2/6 ">
              <div className="w-20 h-20 md:w-40 md:h-40">
                <img src={home} alt="" className="" />
              </div>
              <button
                id="page8_btn_pre-approved1"
                onClick={() => {
                  navigate("/no-down-payment/pre-approved")
                }}
                className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-2 sm:px-2 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
              >
                I need to get pre-approved
              </button>
            </div>
            <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between w-2/6 ">
              <div className="w-20 h-20 md:w-40 md:h-40">
                <img src={home} alt="" className="" />
              </div>
              <button
                id="page8_btn_found-house"
                onClick={() => {
                  navigate("/no-down-payment/found-house/")
                }}
                className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-12 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
              >
                I’ve found a house
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default EightPageCompoOne;