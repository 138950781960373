import React from 'react'
import MainHeading from './MainHeading';
import GrayText from './GrayText';
import horizontalChain from '../../../../assets/horizontalChain.svg'
import verticalChain from '../../../../assets/verticalChain.svg'

function SectionTwo() {
    return (
        <div className='bg-white py-7'>
            <MainHeading text={`No Money Down \n No Broken promises`} />
            <GrayText text="Most zero down-payment programs are complicated, or worse don't work." />
            <div className='px-10'>
                <img src={horizontalChain} alt='horizontalChain image' className='hidden md:block mx-auto mt-10' />
                <img src={verticalChain} alt='horizontalChain image' className='md:hidden  mx-auto' />
            </div>
        </div>
    )
}

export default SectionTwo;